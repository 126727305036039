import { debounce } from "lodash";
import Alpine from "alpinejs";
import AOS from "aos";
import("retinajs");
import Swiper from "swiper/bundle";
const MoveTo = require("moveto");

window.Alpine = Alpine;
Alpine.start();

AOS.init();
window.AOS = AOS;

window.updateAOS = () => {
  setTimeout(() => {
    AOS.refresh();
  }, 100);
};

const swiperSettings = {
  loop: true,
  speed: 10000,
  autoplay: {
    delay: 0,
  },
  slidesPerView: 1.5,
  spaceBetween: -60,
  allowTouchMove: false,
  breakpoints: {
    560: {
      slidesPerView: 1.5,
      spaceBetween: -100,
    },
    640: {
      slidesPerView: 1.5,
      spaceBetween: -110,
    },
    768: {
      slidesPerView: 1.5,
      spaceBetween: -130,
    },
    840: {
      slidesPerView: 1.5,
      spaceBetween: -145,
    },
    900: {
      slidesPerView: 1.5,
      spaceBetween: -155,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: -90,
    },
    1400: {
      slidesPerView: 3,
      spaceBetween: -120,
    },
  },
};
const swiper1 = new Swiper(".swiper-1", swiperSettings);
const swiper2 = new Swiper(".swiper-2", swiperSettings);

// リサイズ時にAutoplayが止まるのを防ぐ
const restartAutoplay = (swipers) => {
  swipers.forEach((swiper) => {
    swiper.autoplay.paused = false;
    swiper.autoplay.start();
  });
};

window.addEventListener(
  "resize",
  debounce(() => {
    restartAutoplay([swiper1, swiper2]);
  }, 500)
);

const moveTo = new MoveTo();

const links = document.querySelectorAll("[data-moveto]");
links.forEach((button) => {
  moveTo.registerTrigger(button);
});

const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

// 画面のサイズ変動があった時に高さを再計算する
window.addEventListener("resize", setFillHeight);

// 初期化
setFillHeight();
